const bgColors: any = {
    new: "#3b82f6",
    Pending: "#3b82f6",
    "In-progress": "#eab308",
    Paused: "#808080",
    Postponed: "#808080",
    Completed: "#16a34a",
  };

  const txColors: any = {
    new: "#FFFFFF",
    Pending: "#FFFFFF",
    "In-progress": "#FFFFFF",
    Paused: "#FFFFFF",
    Postponed: "#FFFFFF",
    Completed: "#FFFFFF",
  };


  export {bgColors, txColors}