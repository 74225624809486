import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import getBase64 from "../../utils/getBase64";
import TextArea from "antd/es/input/TextArea";
import { handleUpdateProject } from "../../api/projects";
import businessUnits from "../../utils/businessUnits";

const { Option } = Select;
export default function UpdateProject({ isVisible, setVisible, project }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('TZS');
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      setVisible(false);
      clearForm();
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleSelectChange = (value: any) => {
    setSelectedCurrency(value);
  };


  const selectBefore = (
    <Select defaultValue="TZS" onChange={handleSelectChange}>
      <Option value="TZS">TZS</Option>
      <Option value="USD">USD</Option>
    </Select>
  );

  const onSubmit = async () => {
    let base64,
      type,
      size = null;

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
      type = selectedFile?.type?.slice(selectedFile?.type - 3);
      size = selectedFile?.size;
    }

    const values = await form.getFieldsValue();
    const data = {
      ...values,
      currency: selectedCurrency,
      thumbnail: base64,
    };
    await mutate({ _id: project?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      title: project?.title,
      budget: project?.budget,
      sponsor: project?.sponsor,
      client: project?.client,
      cluster: project?.cluster,
      link: project?.link,
      mode: project?.mode,
      summary: project?.summary,
      description: project?.description,
      status: project?.status,
      rank: project?.rank,
      thumbnail: project?.thumbnail,
    });
    setSelectedCurrency(project?.currency || 'TZS');
  }, [project, form]);

  return (
    <Modal
      open={isVisible}
      title="Update Project"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateProject"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateProject"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="title"
              label={"Project Title"}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input type="text" placeholder="Project Title" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="budget"
              label={"Project Budget"}
              rules={[
                {
                  required: true,
                  message: "Please enter budget",
                },
              ]}
            >
              <Input addonBefore={selectBefore} type="number" placeholder="Project Budget" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="client"
              label={"Client / Company"}
              rules={[
                {
                  required: true,
                  message: "Please enter client",
                },
              ]}
            >
              <Select placeholder="Client / Company" showSearch>
                <Option value="Karimjee Group">Karimjee Group</Option>
                <Option value="Toyota Tanzania Ltd">Toyota Tanzania Ltd</Option>
                <Option value="Auto Inc.">Auto Inc.</Option>
                <Option value="Karimjee Value Chain">
                  Karimjee Value Chain
                </Option>
                <Option value="Salute Insurance Agency">
                  Salute Insurance Agency
                </Option>
                <Option value="Jacaranda Development">
                  Jacaranda Development
                </Option>
                <Option value="City Motors Sterling Generators">
                  City Motors Sterling Generators
                </Option>
                <Option value="Sunbeam Auto">Sunbeam Auto</Option>
                <Option value="Automark">Automark</Option>
                <Option value="Karimjee Jivanjee Foundation">
                  Karimjee Jivanjee Foundation
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="cluster"
              label={"Cluster"}
              rules={[
                {
                  required: true,
                  message: "Please enter cluster",
                },
              ]}
            >
              <Select placeholder="Cluster" showSearch>
                <Option value="Karimjee Group">Karimjee Group</Option>
                <Option value="Karimjee Mobility">Karimjee Mobility</Option>
                <Option value="Karimjee Energy">Karimjee Energy</Option>
                <Option value="Karimjee Finance">Karimjee Finance</Option>
                <Option value="Karimjee Capital">Karimjee Capital</Option>
                <Option value="Karimjee Hospitality">
                  Karimjee Hospitality
                </Option>
                <Option value="Karimjee Logistics">Karimjee Logistics</Option>
                <Option value="Karimjee Real Estate">
                  Karimjee Real Estate
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="sponsor"
              label={"Project Sponsor"}
              rules={[
                {
                  required: true,
                  message: "Please enter sponsor",
                },
              ]}
            >
              <Select placeholder="Project Sponsor" showSearch>
                {businessUnits.map((option: any) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="link"
              label={"Project Link "}
              rules={[
                {
                  required: true,
                  message: "Please enter project link",
                },
              ]}
            >
              <Input type="text" placeholder="Project Link" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="status"
              label={"Project Status"}
              rules={[
                {
                  required: true,
                  message: "Please enter project status",
                },
              ]}
            >
              <Select placeholder="Project Status">
                <Option selected value={"In-progress"}>
                  In-progress
                </Option>
                <Option value={"Completed"}>Completed</Option>
                <Option value={"Pending"}>Pending</Option>
                <Option value={"Postponed"}>Postponed</Option>
                <Option value={"Paused"}>Paused</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="mode"
              label={"Development"}
              rules={[
                {
                  required: true,
                  message: "Please enter development",
                },
              ]}
            >
              <Select placeholder="Development">
                <Option selected value={"In-house"}>
                  In-house
                </Option>
                <Option value={"Outsourced"}>Outsourced</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="rank"
              label={"Rank (index)"}
              rules={[
                {
                  required: true,
                  message: "Please enter rank",
                },
              ]}
            >
              <Input type="number" step={1} placeholder="Rank (index)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="summary"
              label={"Summary Description"}
              rules={[
                {
                  required: true,
                  message: "Please enter summary",
                },
              ]}
            >
              <TextArea placeholder="Summary" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Thumbnail ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Thumbnail"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
