import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

/*
|---------------------------------------------------------------------
|                             Routes
|---------------------------------------------------------------------
*/

import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/redirects/NotFound";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Denied from "./pages/auth/Denied";
import AuthLayout from "./layouts/AuthLayout";
import Projects from "./pages/home/Projects";
import Signin from "./pages/auth/Signin";
import AllProjects from "./pages/home/AllProjects";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/signin" element={<Signin />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/denied" element={<Denied />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Projects />} />
            <Route path="/manage/projects" element={<AllProjects />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { token } = React.useContext(AuthContext);
  let location = useLocation();
  if (!token) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children;
}

export default App;
