import { GoPlus } from "react-icons/go";
import { useState } from "react";
import CreateProject from "../../components/project/CreateProject";
import { handleFetchProjects } from "../../api/projects";
import { useQuery } from "@tanstack/react-query";
import Something from "../../components/shared/Something";
import UpdateProject from "../../components/project/UpdateProject";
import ProjectWidget from "../../components/project/ProjectWidget";
import UpdateDate from "../../components/project/UpdateDate";
import UpdateRank from "../../components/project/UpdateRank";
import DeleteProject from "../../components/project/DeleteProject";

export default function AllProjects() {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [dateModal, setDateModal] = useState<boolean>(false);
  const [rankModal, setRankModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<any>({});

  const {
    data: projects,
    isFetching,
    refetch,
    isError,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: handleFetchProjects,
  });

  const handleEdit = (project: any) => {
    setSelectedProject(project);
    setUpdateModal(true);
  };

  const handleRank = (project: any) => {
    setSelectedProject(project);
    setRankModal(true);
  };
  const handleDate = (project: any) => {
    setSelectedProject(project);
    setDateModal(true);
  };

  const handleDelete = (project: any) => {
    setSelectedProject(project);
    setDeleteModal(true);
  };

  return (
    <div className="bg-white ">
      <div className="flex flex-col items-center justify-center px-4 py-2 bg-white border-b border-gray-300">
        <button
          onClick={() => setCreateModal(true)}
          className="border flex items-center border-gray-400 px-4 py-1 text-xxs"
        >
          <GoPlus /> Add Project
        </button>
      </div>

      <div className="">
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="sm:mx-44">
            {projects?.map((client: any, index: number) => (
              <div className="mb-8" key={index}>
                <div className="flex flex-col justify-center items-center py-4 mb-4">
                  <div className="text-lg">{client?._id}</div>
                  <div className="text-gray-500 text-xxs mb-2">
                    All projects for {client?._id}
                  </div>
                  <div className="w-16 h-1 bg-fish"></div>
                </div>

                <div className="grid grid-cols-12 gap-8">
                  {client?.projects?.map((project: any, index: number) => (
                    <ProjectWidget
                      project={project}
                      key={index}
                      editable={true}
                      handleEdit={handleEdit}
                      handleRank={handleRank}
                      handleDate={handleDate}
                      handleDelete={handleDelete}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <CreateProject isVisible={createModal} setVisible={setCreateModal} />
      <UpdateProject
        isVisible={updateModal}
        setVisible={setUpdateModal}
        project={selectedProject}
      />
      <UpdateDate
        isVisible={dateModal}
        setVisible={setDateModal}
        project={selectedProject}
      />
      <UpdateRank
        isVisible={rankModal}
        setVisible={setRankModal}
        project={selectedProject}
      />
      <DeleteProject
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        project={selectedProject}
      />
    </div>
  );
}
