import { useQuery } from "@tanstack/react-query";
import ProjectWidget from "../../components/project/ProjectWidget";
import { handleFetchProjects } from "../../api/projects";
import Something from "../../components/shared/Something";
export default function Projects() {
  const {
    data: projects,
    isFetching,
    refetch,
    isError,
  } = useQuery({
    queryKey: ["projects"],
    queryFn: handleFetchProjects,
  });

  return (
    <div className="bg-white">
      <div className="mx-4 sm:mx-16">
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="sm:mx-44">
            {projects?.map((client: any, index: number) => (
              <div className="mb-8" key={index}>
                <div className="flex flex-col justify-center items-center py-4 mb-4">
                  <div className="text-lg">{client?._id}</div>
                  <div className="text-gray-500 text-xxs mb-2">
                    All projects for {client?._id}
                  </div>
                  <div className="w-16 h-1 bg-fish"></div>
                </div>

                <div className="grid grid-cols-12 gap-8">
                  {client?.projects?.map((project: any, index: number) => (
                    <ProjectWidget
                      project={project}
                      key={index}
                      editable={false}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
