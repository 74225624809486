import { IoSettingsOutline } from "react-icons/io5";
import logo from "../../assets/images/white_logo.png";

import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div className=" bg-fish flex flex-col justify-between items-center pt-2">
      <Link to={"/"} className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          <img src={logo} alt="" width={250} />
        </div>
        <div className="flex pb-2">
          <div
            style={{ letterSpacing: 10 }}
            className="text-white text-xs sm:text-xl font-thin"
          >
            GROUP IT PROJECTS
          </div>
          <Link to={"/manage/projects"} className="text-white">
            <IoSettingsOutline />
          </Link>
        </div>
      </Link>
      <div className="h-8 w-full bg-blue-100 border-b border-blue-200"></div>
    </div>
  );
}
