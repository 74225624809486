import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, message } from "antd";
import { useEffect } from "react";
import { handleDeleteProject } from "../../api/projects";

export default function DeleteProject({ isVisible, setVisible, project }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleDeleteProject,
    onSuccess: () => {
      message.success("Deleted Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    await mutate({ _id: project?._id, data: {} });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [project, form]);

  return (
    <Modal
      open={isVisible}
      title="Delete Project"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="deleteProject"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
          danger
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        id="deleteProject"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-col items-center justify-center my-8">
          <div>
            Are you Sure you want to
            <span className="text-automark-dark font-bold mx-1">Delete</span>
            Project
          </div>
          <div className="font-bold text-lg">{project?.title}?</div>
        </div>
      </Form>
    </Modal>
  );
}
