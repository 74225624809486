const businessUnits = [
    "Toyota DSM - Branch",
    "Logistics",
    "Toyota Arusha - Branch",
    "Toyota Mbeya - Branch",
    "Generators (TZ)",
    "Toyota Tanga - Branch",
    "Toyota VMI - GGM",
    "Toyota Kariakoo - Branch",
    "Toyota VMI - North Mara",
    "Toyota VMI - Bulyanhulu",
    "Auto Inc. - Samora Branch",
    "Tyres & Batteries",
    "Bearings",
    "Auto Inc. - Mikocheni Branch",
    "Toyota - New Vehicles",
    "Group Finance",
    "Family Office Functions",
    "Toyota OEM- Distributor service",
    "Toyota - Distributor Service",
    "Group Finance & Accounts",
    "Various",
    "Yamaha - New Vehicles",
    "Group Human Resources",
    "Kahama Court",
    "Sokoine Drive",
    "Group Other",
    "Yamaha - Distributor Service",
    "Group Functions",
    "Group Facilities Management",
    "Group Information Technology",
    "Monga Tea Estate",
    "Toyota OEM Parts",
    "Automark",
    "Operating Lease (TZ)",
    "Insurance",
    "Hero OEM Parts",
    "The Overhang",
    "Group Internal Audit & Sys. Mngt",
    "Hero - 2W",
    "Hero - Distributor Service",
    "Hero OEM 2W",
    "Group Business Improvement",
    "Jacaranda",
    "Group Marketing",
    "Hero Mbeya - Branch",
    "Group Legal & Compliance",
    "Hero DSM- Mnazi Mmoja Branch",
    "Hero Dodoma - Branch",
    "Paints",
    "Hero Kilimanjaro-Branch",
    "Hero OEM - Distributor Service",
    "Group Customer Experience",
    "Lubricants",
    "Generators (KE)",
    "Operating Lease (KE)",
    "Generators OEM Distribution (KE)",
    "Generators (UG)",
    "Hero DSM - Mnazi Mmoja Branch",
    "Hero Arusha - Branch",
    "Innovation Fund",
    "Karimjee House - Zanzibar",
    "Toyota OEM - Distributor service",
    "Motormart",
    "2nd Channel Parts",
    "Operating Lease (UG)",
  ];

  export default businessUnits