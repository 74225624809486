import { message } from "antd";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext<any>(null!);

function getInitialUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}


function getInitialAdmin() {
  const admin = localStorage.getItem("admin");
  return admin ? JSON.parse(admin) : null;
}

function getInitialToken() {
  const token = localStorage.getItem("token");
  return token ? token : null;
}

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(getInitialUser);
  const [token, setToken] = useState<any>(getInitialToken);
  const [admin, setAdmin] = useState<any>(getInitialAdmin);

  const navigate = useNavigate();

  const signinUser = (payload: any) => {
    const { user } = payload;
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    }
  };


  const signinAdmin = (payload: any) => {
    const { admin, token } = payload;
    if (token) {
      localStorage.setItem("admin", JSON.stringify(admin));
      localStorage.setItem("token", JSON.stringify(token));
      setAdmin(admin);
      setToken(token)
      navigate('/admin')
      message.success("Logged in successfully");
    }
  };

  const updateUser = (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };



  const updateAdmin = (admin: any) => {
    localStorage.setItem("admin", JSON.stringify(admin));
    setAdmin(admin);
  };

  const signoutAdmin = () => {
    localStorage.setItem("user", "");
    localStorage.setItem("admin", "");
    localStorage.setItem("token", "");
    setUser(null);
    setToken(null);
    setAdmin(null);
    navigate("/signin");
    message.success("Logged out successfully");
  };

  const handleFetchAuth = async () => {
    if (token) {
      setToken(token);
    }
  };

  let value = {
    user,
    setUser,
    token,
    setToken,
    admin,
    setAdmin,
    updateAdmin,
    signinUser,
    signinAdmin,
    signoutAdmin,
    updateUser,
  };

  useEffect(() => {
    handleFetchAuth();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
