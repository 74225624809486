import deleteData from "../services/deleteData";
import fetchData from "../services/fetchData";
import patchData from "../services/patchData";
import postData from "../services/postData";

const handleFetchProjects = async () => {
  const uri = `v1/projects`;
  const response = fetchData({ uri });
  return response;
};


const handleFetchAllProjects = async () => {
  const uri = `v1/projects/all/raw`;
  const response = fetchData({ uri });
  return response;
};

const handleFetchProject = async (id: any) => {
  const uri = `v1/projects/${id}`;
  const response = fetchData({ uri });
  return response;
};

const handleCreateProject = async (data: any) => {
  const uri = `v1/projects`;
  const response = postData({ data, uri });
  return response;
};

const handleSendEmail = async ({ data }: any) => {
  const uri = `v1/projects/send/email`;
  const response = postData({ data, uri });
  return response;
};

const handleShowProject = async ({ _id, data }: any) => {
  const uri = `v1/projects/${_id}/show`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateProject = async ({ _id, data }: any) => {
  const uri = `v1/projects/${_id}`;
  const response = patchData({ data, uri });
  return response;
};

const handleUpdateDates = async ({ _id, data }: any) => {
  const uri = `v1/projects/${_id}/dates`;
  const response = patchData({ data, uri });
  return response;
};

const handleUploadThumbnail = async ({ _id, data }: any) => {
  const uri = `v1/projects/${_id}/thumbnail/upload`;
  const response = postData({ data, uri });
  return response;
};

const handleDeleteProject = async ({ _id }: any) => {
  const uri = `v1/projects/${_id}`;
  const response = deleteData({ uri });
  return response;
};


export {
  handleCreateProject,
  handleFetchProjects,
  handleFetchProject,
  handleShowProject,
  handleUpdateProject,
  handleUpdateDates,
  handleDeleteProject,
  handleFetchAllProjects,
  handleUploadThumbnail,
  handleSendEmail,
};
