import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  message,
  DatePicker,
  Input,
} from "antd";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateProject } from "../../api/projects";

export default function UpdateRank({ isVisible, setVisible, project }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ _id: project?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ rank: project?.rank });
  }, [project]);

  return (
    <Modal
      open={isVisible}
      title="Update Rank ( Index )"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateRank"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateRank"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="rank"
              label={"Rank (index)"}
              rules={[
                {
                  required: true,
                  message: "Please enter rank",
                },
              ]}
            >
              <Input type="number" step={1} placeholder="Rank (index)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
