import { Button, Modal, Form, Row, Col, message, DatePicker } from "antd";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUpdateProject } from "../../api/projects";

export default function UpdateDate({ isVisible, setVisible, project }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isPending } = useMutation({
    mutationFn: handleUpdateProject,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ _id: project?._id, data });
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {}, [project]);

  return (
    <Modal
      open={isVisible}
      title="Update Dates"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateDates"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isPending}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateDates"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="startDate"
              label={"Start Date"}
              rules={[
                {
                  required: true,
                  message: "Please enter start date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="Start Date"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="endDate"
              label={"End Date"}
              rules={[
                {
                  required: true,
                  message: "Please enter end date",
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder="End Date"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
